<template lang="pug">
v-container(fluid v-if="item").fill-height
  gallery(:images="lightbox_images" :index="lightbox" :options="{closeOnSlideClick: true}" @close="lightbox = null")

  v-row(align="center" justify="center" dense)
    v-col(cols=12 sm=10 md=5)
      v-row(align="center" justify="center")
        v-col(cols=12 lg=12 xl=11)
          v-card(shaped hover)
            v-carousel(:cycle="lightbox == null" :hide-delimiters="item.images < 2" show-arrows-on-hover :height="$vuetify.breakpoint.mdAndUp ? 700 : 400" interval=5000)
              v-carousel-item(v-for="(n, i) in item.images" :key="i" @click="lightbox=i" :src="`/images/${item.article}/${i}.jpg`")

    template(v-if="isItem")
      v-col(cols=12 md=7)
        v-card(shaped)
          .article.headline.mr-5.mt-5 {{ item.article }}
          v-card-title.display-1.primary--text {{ item.name }}
          div
            //- v-card-title.headline.float-right.py-0 {{ item.price }} ₽/м²  // TODO price
            v-card-subtitle.title.white--text.py-0 Ламинат Quick-Step
            v-card-subtitle.py-0 Коллекция {{ item.collection }}
          
          
          //- PriceCalc.py-3(:item="item" :useSurface="isItem || isUnderlay" label="Упаковки")  // TODO price

          v-card-text
            v-container.py-0(fluid)
              v-row
                v-col(cols=12 md=6).details
                  p
                    span.font-italic Размеры доски: 
                    span.text--disabled.float-right {{ item.length }} x {{ item.width }} x {{ item.thickness }} мм
                  p 
                    span.font-italic м²/уп: 
                    span.text--disabled.float-right {{ item.m2 }} м²
                  p 
                    span.font-italic Досок/уп: 
                    span.text--disabled.float-right {{ item.planks }}
                  p
                    span.font-italic Тип фасок: 
                    span.text--disabled.float-right {{ item.grooves_type }}
                  p(v-if="item.grooves_type !== 'Без скоса'") 
                    span.font-italic Количество фасок: 
                    span.text--disabled.float-right 4 канавки
                  p 
                    span.font-italic Метод монтажа: 
                    span.text--disabled.float-right замок Uniclic

                v-col(cols=12 md=6).details-additional
                  p 
                    span.font-italic Цвет: 
                    span.text--disabled.float-right {{ item.color }}
                  p(v-if="item.gloss_level")
                    span.font-italic Глянец: 
                    span.text--disabled.float-right {{ item.gloss_level }}
                  p 
                    span.font-italic Водостойкая технология: 
                    span.text--disabled.float-right {{ item.water_res }}
                  p 
                    span.font-italic Подогрев полов: 
                    span.text--disabled.float-right Совместимо
                  p 
                    span.font-italic Гарантия: 
                    span.text--disabled.float-right {{ item.warranty }} лет
                  p 
                    span.font-italic Класс применения: 
                    span.text--disabled.float-right {{ item.class }}
                  
      v-container#accessories
        v-row(align="center" justify="center" dense)
          v-col(cols=12 md=6 lg=4 xl=3 v-for="a in accessories" v-if="a" :key="a.article")
            v-card(hover :to="{ name: 'Accessory', params: { id: a.article } }")
              .d-flex.flex-no-wrap.justify-space-between
                div
                  v-card-title.subtitle-1.text-no-wrap {{ a.type }}
                  v-card-subtitle {{ a.name }}
                  v-card-subtitle.caption.text--disabled {{ a.article }}
                v-avatar(size=160 tile)
                  v-img(:src="`/images/${a.article}/preview.jpg`")

    v-col(v-else cols=12 sm=6 md=7 lg=6 order="first" order-sm="last")
      v-card(shaped)
        .article.headline.mr-5.mt-5 {{ item.article }}
        v-card-title.display-1.mb-2.primary--text {{ item.name }}
        div
          //- v-card-title.headline.float-right.py-0 {{ item.price }} ₽{{isUnderlay ? '/м²' : ''}}  // TODO price
          v-card-subtitle.title.white--text.py-0 {{ description }}

        //- PriceCalc(:item="item" :pricePerPiece="isSkirting || isProfile" :useSurface="isItem || isUnderlay" :label="isUnderlay ? 'Рулоны' : isClips ? 'Уп' : 'Шт'"
        //- :forcedSurface="isUnderlay ? 15 : isProfile ? 2.15 : isSkirting ? 2.4 : null")  // TODO price

        v-card-text.py-0
          v-container(fluid)
            v-row(justify="space-around")
              template(v-if="isUnderlay")
                v-col(cols=6 sm=12 md=6 lg=4 xl=3)
                  p
                    span.font-italic Толщина: 
                    span.text--disabled.float-right {{ item.thickness }} мм
                v-col(cols=6 sm=12 md=6 lg=4 xl=3)
                  p
                    span.font-italic м² в рулоне: 
                    span.text--disabled.float-right 15 м²
              template(v-if="isClips")
                v-col(cols=6 sm=12 md=6 lg=4)
                  p
                    span.font-italic шт в упаковке: 
                    span.text--disabled.float-right 50
              template(v-if="kitDescription")
                v-col(cols=12)
                  span.body-1 В комплект входит: {{ kitDescription }}
              template(v-if="isProfile || isSkirting")
                v-col(cols=12 md=4 lg=3)
                  span.font-italic Толщина: 
                  span.text--disabled.float-right {{ isSkirting ? '12' : '13' }} мм
                v-col(cols=12 md=4 lg=3)
                  span.font-italic Длина: 
                  span.text--disabled.float-right {{ isSkirting ? '2400' : '2150' }} мм
                v-col(cols=12 md=4 lg=3)
                  span.font-italic {{ isSkirting ? 'Высота:' : 'Ширина:' }}
                  span.text--disabled.float-right {{ isSkirting ? '58' : '48' }} мм
</template>


<style lang="stylus">
.article
  position absolute
  top 0
  right 0
  color rgba(255, 255, 255, 0.15) !important

#accessories .caption
  position absolute
  bottom 0
  left 0
</style>

<script>
import VueGallery from 'vue-gallery'
import { mapGetters } from 'vuex'
import PriceCalc from '@/components/PriceCalc.vue'

export default {
  name: 'Item',
  data() {
    return {
      lightbox: null
    }
  },
  components: {
    gallery: VueGallery,
    PriceCalc
  },
  mounted() {
    //
  },
  computed: {
    ...mapGetters(['getItemById', 'getAccById']),
    isItem() {
      return this.$route.name == 'Item'
    },
    isProfile() {
      return this.item.article.includes('QSINCP')
    },
    isSkirting() {
      return this.item.article.includes('QSSK')
    },
    isUnderlay() {
      return this.item.article.includes('QSUDL')
    },
    isClips() {
      return this.item.article.includes('QSCLIPS')
    },
    kitDescription() {
      switch (this.item.article) {
        case 'QSTOOLA':
          return 'Колодка для обстукивания Quick-Step, установочные распорки для сохранения температурных швов, металлический монтажный ломик'
        case 'QSREPAIR':
          return '1 нож, 1 щетка для чистки, 7 восковых мелков, подушечка для полировки со шкуркой'
        case 'QSSPRAYKIT':
          return 'Швабра с телескопической ручкой, легко отстирывающаяся тряпка из микрофибры, чистящее средство Quick-Step 1000мл'
        default:
          return null
      }
    },
    lightbox_images() {
      return [...Array(this.item.images).keys()].map((i) => `/images/${this.item.article}/${i}_full.jpg`)
    },
    item() {
      return this.isItem ? this.getItemById(this.$route.params.id) : this.getAccById(this.$route.params.id)
    },
    description() {
      return this.isSkirting
        ? 'Плинтус'
        : this.isProfile
        ? 'Профиль INCISO'
        : this.isUnderlay
        ? 'Подложка'
        : this.isItem
        ? 'Ламинат'
        : ''
    },
    profile() {
      return this.item.profile ? this.getAccById(this.item.profile) : null
    },
    skirting() {
      return this.item.skirting ? this.getAccById(this.item.skirting) : null
    },
    accessories() {
      return [
        { ...this.profile, type: 'Профиль INCISO' },
        { ...this.skirting, type: 'Плинтус' },
        { article: 'QSUDLBA15RU', name: 'Basic', type: 'Подложка' },
        { article: 'QSUDLBP15RU', name: 'Basic Plus', type: 'Подложка' },
        this.item.thickness == 8
          ? {
              article: 'QSCLIPSKME7080A',
              name: 'Зажимы для стандартного плинтуса',
              type: 'Зажимы'
            }
          : null,
        this.item.thickness == 9.5
          ? {
              article: 'QSCLIPSKME95A',
              name: 'Зажимы для стандартного плинтуса',
              type: 'Зажимы'
            }
          : null,
        {
          article: 'QSTOOLA',
          name: 'Комплект для монтажа ламината',
          type: 'Комплект для монтажа'
        },
        {
          article: 'QSREPAIR',
          name: '',
          type: 'Ремонтный комплект'
        },
        {
          article: 'QSSPRAYKIT',
          name: '',
          type: 'Комплект для уборки'
        },
        {
          article: 'QSCLEANING1000',
          name: '',
          type: 'Чистящее средство 1л'
        },
        {
          article: 'QSCLEANING2500',
          name: '',
          type: 'Чистящее средство 2,5л'
        }
      ]
    }
  },
  methods: {},
  metaInfo() {
    return this.item
      ? {
          title: `Quick-Step ${this.description} ${this.item.name} - Интернет-магазин «Ламинат От и До»`,
          meta: [
            {
              name: 'description',
              content: `Quick-Step ${this.item.article} ${this.description} ${this.item.name}`
            },
            {
              name: 'keywords',
              content: `${this.item.article}, ${this.item.name}, ${this.description}`
            }
          ],
          links: [
            {
              rel: 'canonical',
              href: `https://laminat-syzran.ru${this.$route.path}`
            }
          ]
        }
      : { title: 'Quick-Step - Интернет-магазин «Ламинат От и До»' }
  }
}
</script>