<template lang="pug">
v-container
  v-row(no-gutters).pb-12
    v-navigation-drawer(v-model="show_filters" color="primary" temporary absolute floating clipped)
      v-list(dense nav)
        v-list-item
          v-list-item-icon
            v-icon mdi-content-save
          v-list-item-content
            v-list-item-title Коллекции
    v-col(cols=12)
      v-row(align="center" justify="center")
        v-col(cols=6 md=4 lg=3 xl=2 v-for="item in pageItems" :key="item.article")
          v-card(hover :to="{ name: 'Item', params: { id: item.article } }")
            v-img.align-end(aspect-ratio=1 contain :src="`/images/${item.article}/preview.jpg`")
            v-card-title.subtitle-2.text-no-wrap {{ item.name }}
            v-card-subtitle Quick-Step {{ item.collection }}
            v-card-text
              span.caption.text--disabled {{ item.article }}
              //- span.float-right.subtitle-1.white--text(v-if="item.price") {{ item.price }} ₽/м²  // TODO price
  v-footer(fixed)
    v-pagination(v-model="page" :length="pagesCount" color="primary")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ItemsList',
  data() {
    return {
      show_filters: false
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(['pageItems', 'pagesCount', 'collections', 'classes', 'colors']),
    page: {
      get() {
        return this.$store.state.page
      },
      set(value) {
        this.$store.commit('SET_PAGE', value)
      }
    }
  },
  methods: {},
  metaInfo: {
    title: 'Интернет-магазин «Ламинат От и До»',
    meta: [
      {
        name: 'description',
        content: 'Интернет-магазин «Ламинат От и До». Низкие цены! Доставка по г. Сызрань'
      },
      {
        name: 'keywords',
        content:
          'Quick-Step, Castle, Classic, Impressive, Majestic, Rustic, Eligna, Desire, Perspective, купить ламинат, сызрань'
      }
    ],
    links: [{ rel: 'canonical', href: `https://laminat-syzran.ru/laminat/` }]
  }
}
</script>