<template lang="pug">
  v-container.pa-0.my-5(fluid)
    v-row(justify="center" align="center" no-gutters)
      v-col(cols=12 lg=9 xl=7)
        v-row(align="center" justify="space-around" no-gutters)
          template(v-if="forcedSurface || item.m2")
            v-col(cols=3 md=2)
              v-text-field(:value="surface" @input="surfaceInput" :label="useSurface ? 'Площадь м²' : 'Длина (м)'" type="number" step="0.1" min="1" max="5000" autocomplete="off")
            .headline =
          v-col(cols=3 md=2)
            v-text-field(:value="count" @input="countInput" :label="label" type="number" min="1" max="200" autocomplete="off")
          .headline =
          v-col(cols=3 md=3)
            .headline {{ totalPrice }} ₽

      v-col(cols=12).d-flex.justify-center
        v-btn(v-if="in_cart" color="success" disabled)
          .mr-2 В корзине
          v-icon mdi-check
        v-btn(v-else color="primary" @click="countInCart=count; addToCart({...item, count: count, total: totalPrice})")
          .mr-2 В корзину
          v-icon mdi-cart-plus
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PriceCalc',
  props: {
    item: Object,
    useSurface: Boolean,
    pricePerPiece: Boolean,
    forcedSurface: Number,
    label: String
  },
  data() {
    return {
      count: 1,
      countInCart: 0,
      need: null
    }
  },
  mounted() {
    let cartItem = this.getCartItem(this.item.article)
    if (cartItem) {
      this.count = this.countInCart = cartItem.count
      this.countInput(this.count)
    }
  },
  computed: {
    ...mapGetters(['getCartItem']),
    in_cart() {
      return this.count == this.countInCart
    },
    m2() {
      return this.forcedSurface ? this.forcedSurface : this.item.m2 || 1
    },
    totalPrice() {
      return Math.round(
        this.count * (this.pricePerPiece ? 1 : this.m2) * this.item.price
      )
    },
    surface() {
      return this.need > 0 ? this.need : this.m2
    }
  },
  methods: {
    ...mapActions(['addToCart']),
    surfaceInput(surface) {
      this.need = surface
      this.count = Math.ceil(this.need / this.m2)
    },
    countInput(count) {
      this.count = Math.round(count)
      this.need = Math.floor(this.count * this.m2 * 100) / 100
    }
  }
}
</script>