<template lang="pug">
v-container.fill-height
  vue-recaptcha(ref="recaptcha" theme="dark" size="invisible" sitekey="6LfbDO4UAAAAAObv97A6B9o9AIkATytN4pj36xN6" :loadRecaptchaScript="true" @verify="onVerify")
  v-overlay(:value="processingOrder")
    v-progress-circular(indeterminate size="64")
    v-dialog(v-model="orderResultDialog" persistent max-width="600px")
      v-card
        template(v-if="orderResult")
          v-card-title.justify-center
            span.headline Заказ подтвержден
          v-card-text.text-center
            v-icon(size=120 color="success") mdi-check-circle-outline
        template(v-else)
          v-card-title.justify-center
            span.headline Ошибка при подтверждении заказа
          v-card-text.text-center
            v-icon(size=120 color="error") mdi-alert-circle-outline
        v-card-actions
          v-spacer
          v-btn(color="primary" @click="closeOrder") OK
          v-spacer
  v-dialog(v-model="orderDialog" persistent max-width="600px")
    v-card
      v-card-title
        span.headline Оформление заказа
      v-card-text
        v-form(ref="form" v-model="valid" lazy-validation)
          v-container
            v-row
              v-col(cols="12")
                v-text-field(v-model="firstName" counter=50 :rules="firstNameRules" label="Имя" required)
              v-col(cols="12")
                v-text-field(v-model="lastName" counter=50 :rules="lastNameRules" label="Фамилия" required)
              v-col(cols="12")
                v-text-field(v-model="address" counter=100 :rules="addressRules" label="Адрес" required)
              v-col(cols="12")
                v-text-field(v-model="email" :rules="emailRules" label="Email" required)
              v-col(cols="12")
                v-text-field(v-model="phone" counter=30 :rules="phoneRules" label="Телефон" type="tel" required)

      v-card-actions
        v-spacer
        v-btn(text @click="orderDialog = false") Закрыть        
        v-btn(color="success" text @click="validate" :disabled="!valid") Подтвердить

  v-row(align="center" justify="center" dense v-if="totalSum > 0")
    v-col(cols=12 lg=10 xl=7 v-for="item in cart" :key="item.article")
      CartItem(:item="item")

    v-col(cols=12 lg=10 xl=7)
      v-card.py-4
        v-row(justify="center").d-flex.flex-no-wrap.justify-space-around
          v-card-title К оплате: {{ totalSum.toLocaleString('ru-RU') }} ₽
          v-card-actions            
            v-btn(large color="primary" @click.stop="openOrder") Оформить заказ

  v-row(align="center" justify="center" dense v-else).flex-column.flex-no-wrap
    v-icon.pa-5(size=100) mdi-cart-off
    span.headline Сначала добавьте товары в корзину
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CartItem from '@/components/CartItem.vue'
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'

export default {
  name: 'Cart',
  data() {
    return {
      captchaResp: null,
      orderDialog: false,
      orderResultDialog: false,
      orderResult: false,
      processingOrder: false,
      valid: true,
      firstName: '',
      lastName: '',
      firstNameRules: [
        v => !!v || 'Введите имя',
        v => (v && v.length <= 50) || 'Имя не должно превышать 50 символов'
      ],
      lastNameRules: [
        v => !!v || 'Введите фамилию',
        v => (v && v.length <= 50) || 'Фамилия не должна превышать 50 символов'
      ],
      address: '',
      addressRules: [
        v => !!v || 'Введите адрес',
        v => (v && v.length <= 100) || 'Адрес не должен превышать 100 символов'
      ],
      email: '',
      emailRules: [
        v => !!v || 'Введите e-mail',
        v => /.+@.+\..+/.test(v) || 'Неправильный адрес e-mail'
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'Введите номер телефона',
        v => (v && v.length <= 30) || 'Телефон не должен превышать 30 символов'
      ]
    }
  },
  components: {
    CartItem,
    VueRecaptcha
  },
  mounted() {},
  computed: {
    ...mapState(['cart']),
    ...mapGetters(['totalSum'])
  },
  methods: {
    ...mapActions(['emptyCart']),
    onVerify(response) {
      this.captchaResp = response
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.processingOrder = true
        this.orderDialog = false
        let resp = await axios.post('/api/order', {
          cart: this.cart,
          first_name: this.firstName,
          last_name: this.lastName,
          address: this.address,
          email: this.email,
          phone: this.phone,
          recaptcha: this.captchaResp
        })
        this.orderResult = resp.data
        this.orderResultDialog = true
        this.$ga.event('ecommerce', 'checkout')
      }
    },
    openOrder() {
      this.orderDialog = true
      this.$refs.recaptcha.execute()
      this.$ga.event('ecommerce', 'begin_checkout')
    },
    closeOrder() {
      this.processingOrder = false
      this.orderResultDialog = false
      if (this.orderResult == true) {
        this.emptyCart()
        this.$router.push({ name: 'ItemsList' })
      } else {
        this.$refs.recaptcha.reset()
        this.$refs.recaptcha.execute()
        this.orderDialog = true
        this.orderResult = false
      }
    }
  },
  metaInfo: {
    title: 'Корзина - Интернет-магазин «Ламинат От и До»',
    meta: [
      {
        name: 'description',
        content:
          'Интернет-магазин «Ламинат От и До». Низкие цены! Доставка по г. Сызрань'
      },
      {
        name: 'keywords',
        content:
          'Quick-Step, Castle, Classic, Impressive, Majestic, Rustic, Eligna, Desire, Perspective, купить ламинат, сызрань'
      }
    ],
    links: [
      { rel: 'canonical', href: `https://laminat-syzran.ru/laminat/cart` }
    ]
  }
}
</script>