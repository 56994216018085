<template lang="pug">
v-content.grey.darken-3
  v-app-bar(app dense color="primary")
    v-row(align="center")
      v-col(cols=2).d-flex.justify-start
        v-btn(icon :to="{ name: 'Home' }" exact v-if="$route.name == 'ItemsList'")
          v-icon mdi-home
        template(v-else)
          v-btn(icon :to="{ name: 'ItemsList' }" exact v-if="$route.name == 'Item'")
            v-icon mdi-arrow-left
          v-btn(icon @click="$router.go(-1)" v-else)
            v-icon mdi-arrow-left
      v-col.d-flex.justify-space-around
        v-toolbar-title.hidden-xs-only
          h2.headline(v-if="$route.name == 'Cart'") Корзина
          h2.headline(v-else) Выберите пол своей мечты!
      v-col(cols=2).d-flex.justify-end
        v-btn(:to="{ name: $route.name == 'Cart' ? 'ItemsList' : 'Cart' }" exact text) 
          .title.mr-3 {{ totalSum.toLocaleString('ru-RU') }} ₽
          v-icon(v-if="totalSum > 0" right) mdi-cart
          v-icon(v-else right) mdi-cart-outline
  transition(name="fade" mode="out-in")
    router-view(:key="$route.name") 
</template>


<style lang="stylus">
.fade-enter-active, .fade-leave-active
  transition opacity 0.25s

.fade-enter, .fade-leave-to
  opacity 0
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Shop',
  data() {
    return {
      //
    }
  },
  methods: mapActions(['setItems', 'loadCart']),
  mounted() {
    if (localStorage.cart) this.loadCart(JSON.parse(localStorage.cart))
    this.setItems()
  },
  computed: {
    ...mapGetters(['totalSum'])
  }
}
</script>