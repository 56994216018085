<template lang="pug">
v-card.cart-item(hover :to="{ name: item.m2 ? 'Item' : 'Accessory', params: { id: item.article } }")
  v-btn.delete(icon @click.prevent="removeFromCart(item)")
    v-icon mdi-delete
  v-container.pa-0.text-no-wrap
    v-row(align="center" justify="center" no-gutters)
      v-col.pa-0(cols=4 sm=2)
        v-img(:src="`/images/${item.article}/preview.jpg`")
      v-col(cols=12 sm=7 order="1" order-sm="0")
        v-card-title {{ item.name }}
        v-card-subtitle {{ itemType }}
        v-card-subtitle.py-0.caption.text--disabled {{ item.article }}
      v-col(cols=8 sm=3)
        v-card-title.headline {{ item.total.toLocaleString('ru-RU') }} ₽
        v-card-subtitle.body-2 {{ item.count }} {{ countType }} 
          span(v-if="!isClips && !isOther") ({{ totalSurface }} м{{isProfile || isSkirting ? '' : '²'}})
</template>

<style lang="stylus">
.cart-item
  .delete
    position absolute
    top 5px
    right 5px
</style>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CartItem',
  props: {
    item: Object
  },
  data() {
    return {}
  },
  mounted() {},
  computed: {
    isProfile() {
      return this.item.article.includes('QSINCP')
    },
    isSkirting() {
      return this.item.article.includes('QSSK')
    },
    isUnderlay() {
      return this.item.article.includes('QSUDL')
    },
    isClips() {
      return this.item.article.includes('QSCLIPS')
    },
    isOther() {
      return (
        this.item.article.includes('QSCLEANING') ||
        ['QSTOOLA', 'QSREPAIR', 'QSSPRAYKIT'].includes(this.item.article)
      )
    },
    totalSurface() {
      let m2 = this.isProfile
        ? 2.15
        : this.isSkirting
        ? 2.4
        : this.isUnderlay
        ? 15
        : this.item.m2
      return Math.floor(m2 * this.item.count * 100) / 100
    },
    itemType() {
      return this.isProfile
        ? 'Профиль INCISO'
        : this.isSkirting
        ? 'Плинтус'
        : this.isUnderlay
        ? 'Подложка'
        : this.isClips || this.isOther
        ? ''
        : 'Ламинат Quick-Step'
    },
    countType() {
      return this.isProfile || this.isSkirting || this.isOther ? 'шт' : 'уп'
    }
  },
  methods: {
    ...mapActions(['removeFromCart'])
  }
}
</script>